
export const api = {

  setAuthToken: authToken => {
    if (!authToken){
      throw new Error('Unable to configure API. An auth token is required');
    }

    // Set auth token for API requests
    api.identity.setToken(authToken, 'Bearer');

  },

  configure: ({ $axios, env }) => {
    // Create a custom axios instance for the can-spam-identity api
    api.identity = $axios.create();
    api.identity.setBaseURL(env.api.identity.baseUrl);
  }
};

export default async (context, inject) => {
  api.configure(context);
  inject('api', api);
};
