import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d10ee256 = () => interopDefault(import('../pages/error/index.vue' /* webpackChunkName: "pages/error/index" */))
const _09b417e7 = () => interopDefault(import('../pages/sign-out/index.vue' /* webpackChunkName: "pages/sign-out/index" */))
const _b3416a56 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _3a95164c = () => interopDefault(import('../pages/users/create/index.vue' /* webpackChunkName: "pages/users/create/index" */))
const _7bb1fc8a = () => interopDefault(import('../pages/users/_username/index.vue' /* webpackChunkName: "pages/users/_username/index" */))
const _525f0f9c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c1847cae = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _d10ee256,
    name: "error"
  }, {
    path: "/sign-out",
    component: _09b417e7,
    name: "sign-out"
  }, {
    path: "/users",
    component: _b3416a56,
    name: "users"
  }, {
    path: "/users/create",
    component: _3a95164c,
    name: "users-create"
  }, {
    path: "/users/:username",
    component: _7bb1fc8a,
    name: "users-username"
  }, {
    path: "/",
    component: _525f0f9c,
    name: "index"
  }, {
    path: "/*",
    component: _c1847cae,
    name: "all"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
