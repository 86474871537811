/**
 * Middleware to ensure user is logged in.
 * Handles error and success (new token) responses from server when logging in.
 * Takes care of maintaining and renewing the login session (on route change)
 * if the user is already logged in.
 * 
 * TIP: Can pass a valid auth token in the url to bypass manual login. E.g.
 *    http://localhost:3000?token=<JWT_AUTH_TOKEN>
 * 
 * USAGE: 
 * Add this middleware to any pages requiring an authenticated user.
 * // some-page.vue
 * <script>
 * export default { 
 *    middleware: 'authenticated',
 * };
 * </script>
 */
export default async ({ app, route, redirect }) => {

  // Query params returned from login attempt
  let errorCode = route.query['code'];
  let authToken = route.query['token'];

  app.$auth.setToken(authToken);

  // Handle error response from login attempt
  if (errorCode === 'internal' || errorCode === 'assertion_error') {
    // Returning a never-to-resolve promise here to block further 
    // loading of page/modules before we finish redirecting the page.
    return new Promise((resolve, reject) => {
      redirect(`/error?code=${errorCode}`);
    });
  }

  // Check for login session, else prompt login
  if (!app.$auth.isLoggedIn()) {

    // Returning a never-to-resolve promise here to block further 
    // loading of page/modules before we finish redirecting the page.
    return new Promise((resolve, reject) => {
      app.$auth.login();
    });

  } else {
    // User is logged in

    // The user did something to warrant keeping their login session alive
    // (e.g. navigated to a new page), so extend the session expiration.
    app.$auth.renewSessionExpiry();

    // Set token on backend APIs
    app.$api.setAuthToken(app.$auth.getToken());

    // Check user permissions
    return app.$auth.getLoggedInUser()
    .then(user => {
      const ALLOWED_USER_ROLES = ['admin', 'user-manager', 'read'];
      if(!ALLOWED_USER_ROLES.includes(user.role)){
        redirect(`/error?code=forbidden`);
      }
    })
    .catch(err => {
      // This error handling logic could be improved
      redirect(`/error?code=internal`);
    });
}

};
