import Vue from 'vue';

const errorMessages = {
  required: 'This field is required',
  email: 'This field should be an email address',
  phoneNumber: 'This field should be a valid phone number',
  zip: 'This field should be a valid zip code',
  date: 'This field should be a valid date in the format YYYY-MM-DD',
  dateMinimum: 'This field should be a date no older than 1970-01-01',
  maxLength: 'This field should be less than 255 characters'
};

Vue.mixin({
  methods: {
    setValidity(invalidArray, errorSpan, inputEl) {
      if (invalidArray.length) {
        let completeErrorMsg = '';
        if (inputEl && errorSpan) {
          inputEl.classList.add('invalid');
          invalidArray.forEach((singleMessage, index) => {
            if (index === 0) {
              completeErrorMsg += errorMessages[singleMessage] + ' ';
            } else {
              completeErrorMsg +=
                errorMessages[singleMessage].replace('This field', 'and ') + ' ';
            }
          });
          errorSpan.innerHTML = completeErrorMsg;
          errorSpan.style.display = 'block';
        }
      } else {
        if (inputEl && errorSpan) {
          inputEl.classList.remove('invalid');
          errorSpan.style.display = 'none';
        }
      }
    },
    maxLength(value) {
      if (value && value.length <= 255) {
        return true;
      } else {
        return false;
      }
    },
    isRequired(value) {
      if (value) return true;
    },
    isEmail(value) {
      if (
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(value).toLowerCase()
        )
      ) {
        return true;
      }
    },
    isPhoneNumber(value) {
      if (
        value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
      ) {
        return true;
      }
    },
    isZip(value) {
      if (value.match(/^\d{5}(-\d{4})?$/)) {
        return true;
      }
    },
    validateThis(validationContext) {
      const { value, validationRules, errorSpan, inputEl } = validationContext;
      let invalidArray = [];
      validationRules.forEach(rule => {
        switch (rule) {
          case 'required':
            if (!this.isRequired(value)) invalidArray.push(rule);
            break;
          case 'email':
            if (!this.isEmail(value)) invalidArray.push(rule);
            break;
          case 'phoneNumber':
            if (!this.isPhoneNumber(value)) invalidArray.push(rule);
            break;
          case 'zip':
            if (!this.isZip(value)) invalidArray.push(rule);
            break;
          case 'maxLength':
            if (!this.maxLength(value)) {
              invalidArray.push(rule);
            }
            break;
          default:
            break;
        }
      });
      this.setValidity(invalidArray, errorSpan, inputEl);
      return invalidArray;
    }
  }
});
