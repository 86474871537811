import jwt from 'jsonwebtoken';

class AuthService {
  
  static get TOKEN_STORAGE_KEY(){
    return 'authToken';
  }

  constructor($api, config){
    this.$api = $api;
    this.config = config || {};
    this.sessionTimeoutId = null;
  }

  setToken(authToken) {
    if(authToken){
      // Persist token across page refreshes and new browser tabs
      localStorage.setItem(AuthService.TOKEN_STORAGE_KEY, authToken);
    }
  }

  getToken() {
    return localStorage.getItem(AuthService.TOKEN_STORAGE_KEY);
  }

  isLoggedIn(){
    return this.getToken() && !this.tokenIsExpired();
  }

  tokenIsExpired(){
    let isExpired = true;
    let token = this.getToken();
    let tokenData = token && jwt.decode(token);
    let expirationSeconds = tokenData && tokenData.exp || 0;
    let expirationMs = expirationSeconds * 1000;
    let nowMs = Date.now();

    if(expirationMs > nowMs){
      isExpired = false;
    }

    return isExpired;
  }

  renewSessionExpiry() {
    let sessionLengthMs = 1000 * 60 * this.config.auth.loginSessionLengthInMinutes;

    if(this.sessionTimeoutId){ clearTimeout(this.sessionTimeoutId); }
    this.sessionTimeoutId = setTimeout(() => {
      this.logout();
    }, sessionLengthMs);
  }

  login() {
    this.clearSession();
    let identityConfig = this.config && this.config.api && this.config.api.identity;
    window.location.href = `${identityConfig.baseUrl}${identityConfig.loginPath}`;
  }

  logout() {
    this.clearSession();

    let identityConfig = this.config && this.config.api && this.config.api.identity;
    let logoutUrl = `${identityConfig.baseUrl}${identityConfig.logoutPath}`;
    window.location.href = logoutUrl;
  }

  clearSession(){
    localStorage.removeItem(AuthService.TOKEN_STORAGE_KEY);
  }

  getLoggedInUser() {
    return this.$api.identity.get('public/users/me')
      .then(res => {
        let user = res.data;
        return user;
      });
  }

};



export default async (context, inject) => {
  let authService = new AuthService(context.app.$api, context.env);
  inject('auth', authService);
};
