<template>
  <div class="cspam-app">
    <header class="cspam-header">
      <div class="cspam-header__container">
        <h1 class="cspam-title">
          <span class="cspam-title__logo">
            <img
              src="/genentech.png"
              alt="Genentech">
          </span>
          <span class="cspam-title__inner">CAN SPAM Admin</span>
        </h1>
        <div class="auth-container">
          <Button
            class="sign-in-out-button"
            :button-label="authButtonLabel"
            @clicked="signInOrOut()"/>
        </div>
      </div>
    </header>

    <main class="cspam-main">
      <div class="cspam-main__container">
        <nuxt/>
      </div>
    </main>

    <footer class="cspam-footer">
      <div class="cspam-footer__container">
        <div class="cspam-footer__group">
          <p class="cspam-footer__copyright">&copy; {{ getDate }} Genentech USA, Inc. All rights reserved.</p>
        </div>
        <div class="cspam-footer__group">
          <img
            src="/genentech.png"
            alt="Genentech">
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  components: {
    Button: () => import('@/components/Button/Button.vue'),
  },
  data() {
    return {
      authButtonLabel: this.$nuxt.$auth.isLoggedIn() ? 'Sign out' : 'Sign in'
    };
  },
  computed: {
    getDate: function () {
      return (new Date()).getFullYear();
    }
  },
  methods: {
    signInOrOut: function () {
      if(this.$nuxt.$auth.isLoggedIn()){
        this.$nuxt.$auth.logout();
      }else{
        this.$nuxt.$auth.login();
      }
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";

html,
body,
#__nuxt,
#__layout {
  height: 100%;
}

html {
  font-family: Verdana, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.cspam-app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cspam-header,
.cspam-main,
.cspam-footer {

  &__container {
    margin: 0 auto;
    padding: 0 2rem;
    max-width: 76rem;
  }
}

.cspam-header {
  background-color: $headerBackgroundColor;
  color: $headerTextColor;
  padding: 1rem 0;

  &__container {
    display: flex;
  }

  .cspam-title {
    display: flex;
    flex: 1;
    align-items: center;

    &__logo {
      margin-top: 1rem;
    }

    &__inner {
      border-left: 3px solid $headerTextColor;
      font-family: trade-gothic-next, sans-serif;
      font-style: normal;
      font-weight: 700;
      margin-left: 1rem;
      padding: 1rem 0 1rem 1rem;
      text-transform: uppercase;
    }
  }

  .auth-container {
    align-items: center;
    display: flex;
    
    .sign-in-out-button {
      border: 1px solid transparent;
      background-color: #748190;
      color: white;
    }
  }

}

.cspam-main {
  flex-grow: 1;

  &__content {
    margin-top: 3rem;
    max-width: 54rem;
  }
}

.cspam-footer {
  background-color: $footerBackgroundColor;
  color: $footerTextColor;
  margin-top: 6rem;
  padding: 3rem 0;

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: $breakpoint) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__link-list {
    display: flex;
    list-style: none;
    margin-top: 2rem;
    padding-left: 0;

    @media (min-width: $breakpoint) {
      margin-top: 0;
    }
  }

  &__link-item {
    border-left: 1px solid $footerTextColor;
    margin-left: 2rem;
    padding-left: 2rem;

    &:first-child {
      border: none;
      margin-left: 0;
      padding-left: 0;
    }
  }

  &__link {
    color: $footerTextColor;
    text-decoration: none;
  }

  &__copyright {
    margin-top: 1rem;
  }
}
</style>
